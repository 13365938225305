import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getData, postData } from "../../../../backend/api";
import axios from "axios";
import withLayout from "../../../public/layout";
import moment from "moment";
import { FormContainer } from "../../../public/login/styles";
import AutoForm from "../../../core/form";
import { useSelector } from "react-redux";
import PopupView from "../../../core/popupview";
import { Title } from "../../../core/elements";
import { GetIcon } from "../../../../icons";

// Refined Styled Components
const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
  padding: 1rem 1.5rem 3rem 1.5rem;
`;

const Content = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  animation: fadeIn 0.6s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  font-weight: 800;
  background: linear-gradient(135deg, #1e293b 0%, #334155 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  letter-spacing: -0.025em;
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: #64748b;
  font-weight: 400;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  isolation: isolate;

  &:hover {
    transform: translateY(-6px);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 1.25rem;
    padding: 2px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 220px;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);

  ${Card}:hover & {
    transform: scale(1.08);
  }
`;

const Badge = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1.25rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  background: ${(props) =>
    props.isPaid ? "rgba(37, 99, 235, 0.95)" : "rgba(5, 150, 105, 0.95)"};
  color: white;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-1px) scale(1.02);
  }
`;

const CardContent = styled.div`
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 12px;
  background: ${(props) =>
    props.isPaid ? "rgba(37, 99, 235, 0.1)" : "rgba(5, 150, 105, 0.1)"};
  color: ${(props) => (props.isPaid ? "#2563eb" : "#059669")};
  transition: all 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.1) rotate(-5deg);
  }
`;

const CardTitle = styled.h3`
  font-size: 1.375rem;
  font-weight: 700;
  color: #1e293b;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Description = styled.p`
  color: #64748b;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1rem;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 1.25rem;
  border-top: 1px solid #e2e8f0;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const Price = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => (props.isPaid ? "#2563eb" : "#059669")};
  letter-spacing: -0.025em;
`;

const TaxInfo = styled.span`
  font-size: 0.75rem;
  color: #94a3b8;
  font-weight: 500;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 0.9375rem;
  letter-spacing: 0.025em;
  background: ${(props) =>
    props.isPaid
      ? "linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%)"
      : "linear-gradient(135deg, #059669 0%, #047857 100%)"};
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px -1px ${(props) => (props.isPaid ? "rgba(37, 99, 235, 0.2)" : "rgba(5, 150, 105, 0.2)")};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 12px -1px ${(props) => (props.isPaid ? "rgba(37, 99, 235, 0.3)" : "rgba(5, 150, 105, 0.3)")};
  }

  &:active {
    transform: translateY(0);
  }
`;

// Stream Card Component
const StreamCard = ({ stream, setMessage, setLoaderBox }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState("email");
  const [index, setIndex] = useState(0);
  const themeColors = useSelector((state) => state.themeColors);
  const totalAmount = calculateTotalAmount(stream.amount, stream.taxPercentage);

  const handlePurchaseClick = () => {
    if (stream.isPaid) {
      setIsModalOpen(true);
    } else {
      // Handle free enrollment directly
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formInput = [
    {
      type: "text",
      placeholder: "Enter your email",
      name: "email",
      validation: "email",
      default: "",
      label: "Email",
      minimum: 5,
      maximum: 40,
      required: true,
      icon: "email",
    },
  ];
  const isCreatingHandler = (value, callback) => {};
  const submitChange = async (post) => {
    setLoaderBox(true);
    try {
      getData(post, "user").then((response) => {
        if (response?.data?.response.length > 0) {
          const studentId = response.data.response[0].student?._id;
          if (studentId) {
            // User exists, proceed to payment
            handleAuthSubmit({ studentId });
          } else {
            setIndex((prev) => prev + 1);
            setMessage({
              type: 1,
              content: "No Corresponding User Found.",
              icon: "error",
            });
            return;
          }
        } else {
          setIndex((prev) => prev + 1);
          // User doesn't exist, ask for password
          setStep("password");
        }
      });
    } catch (err) {
      setMessage({
        type: 1,
        content: "Something went wrong. Please try again.",
        icon: "error",
      });
      return;
    } finally {
      setLoaderBox(false);
    }
  };
  const RegisterInput = [
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Gender",
      name: "gender",
      validation: "",
      default: "",
      label: "Gender",
      required: false,
      tag: true,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Male, Female",
    },
    {
      type: "date",
      placeholder: "DOB",
      name: "dob",
      validation: "",
      minDate: moment().add(-70, "years").toDate(),
      default: moment().toDate(),
      label: "DOB",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Email",
      name: "email",
      validation: "",
      default: "",
      label: "Email",
      required: true,
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Mobile",
      name: "mobile",
      validation: "",
      default: "",
      label: "Mobile",
      required: true,
      view: true,
      tag: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Educational Qualification",
      name: "educationalQualification",
      validation: "",
      default: "",
      label: "Educational Qualification",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      label: "Address",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Father's Name",
      name: "fatherName",
      validation: "",
      default: "",
      label: "Father's Name",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Mother's Name",
      name: "motherName",
      validation: "",
      default: "",
      label: "Mother's Name",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Nationality",
      name: "nationality",
      validation: "",
      default: "",
      label: "Nationality",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "State",
      name: "state",
      validation: "",
      default: "",
      label: "State",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "image",
    //   placeholder: "Image",
    //   name: "image",
    //   validation: "",
    //   default: "",
    //   label: "Image",
    //   required: false,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
    {
      type: "password",
      placeholder: "Enter Your Password",
      name: "password",
      validation: "password",
      info: "At least one uppercase letter (A-Z) \n At least one lowercase letter (a-z) \n At least one digit (0-9) \n At least one special character (@, $, !, %, *, ?, &) \n Minimum length of 8 characters",
      default: "",
      label: "Password",
      minimum: 0,
      maximum: 16,
      required: true,
      add: true,
    },
  ];
  const submitRegisterChange = async (post) => {
    setLoaderBox(true);

    try {
      postData(post, "student").then((response) => {
        if (response.status === 400) {
          setMessage({
            type: 1,
            content: response.customMessage,
            icon: "error",
          });
          return;
        }

        const studentId = response.data.data._id;

        if (response.data.success === true) {
          handleAuthSubmit({ studentId });
        } else {
          setMessage({
            type: 1,
            content: response.data.message || "Failed to create account",
            icon: "error",
          });
          return;
        }
      });
    } catch (err) {
      setMessage({
        type: 1,
        content: "Something went wrong. Please try again.",
        icon: "error",
      });
      return;
    } finally {
      setLoaderBox(false);
    }
  };

  const handleAuthSubmit = async (data) => {
    try {
      openPaymentPage({ student: data.studentId });
    } catch (error) {
      console.error("Error during authentication:", error);
    }
  };

  const openPaymentPage = async ({ student }) => {
    try {
      // Fetch user data from your backend
      const response = await axios.get(`${process.env.REACT_APP_API}user`, {
        params: { student },
      });

      // Close the modal
      setIsModalOpen(false);

      if (response.data.success === true) {
        console.log(
          response.data.response[0],
          "User data fetched successfully"
        );

        // Call payment handler with required data
        paymentHandler({
          student: response.data.response[0]._id,
          amount: totalAmount, // Ensure totalAmount is defined and accessible
          stream: stream._id, // Ensure stream is defined and accessible
        });
      }
    } catch (error) {
      console.error(
        "Error fetching user data or creating payment session:",
        error
      );
    }
  };

  const paymentHandler = async (data) => {
    try {
      const response = await postData(
        {
          student: data.student,
          stream: data.stream,
          amount: data.amount,
        },
        "purchase-plan"
      );

      if (response.status === 400) {
        setMessage({
          type: 1,
          content: response.customMessage,
          icon: "error",
        });
        return;
      }
      const options = {
        key: response?.data?.key, // Your Razorpay Key ID
        amount: response?.data?.order?.amount, // Amount in smallest currency unit (e.g., paise for INR)
        currency: response?.data?.order?.currency,
        name: "AQL",
        description: "Stream Purchased",
        order_id: response?.data?.order?.id, // Razorpay order ID
        handler: async function (paymentResponse) {
          // Handle successful payment here
          try {
            const verificationResponse = await postData(
              { ...paymentResponse },
              "purchase-plan/verify-payment"
            );
            console.log(verificationResponse, "Payment verified successfully");
          } catch (error) {
            console.error("Error verifying payment:", error);
          }
        },
        prefill: {
          name: response?.data?.purchase?.student?.userDisplayName || "", // Optional prefill values
          email: response?.data?.purchase?.student?.email || "",
          contact: response?.data?.purchase?.student?.mobile || "",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);

      // Error handling for Razorpay instance
      rzp1.on("payment.failed", function (response) {
        console.error("Payment failed:", response.error);
        setMessage({
          type: 1,
          content: "Payment failed. Please try again.",
          icon: "error",
        });
      });

      rzp1.open();
    } catch (error) {
      console.error(
        "Error creating payment session or opening Razorpay:",
        error
      );
    }
  };

  return (
    <>
      <Card>
        <ImageContainer>
          <Image
            src={process.env.REACT_APP_CDN + stream.logo}
            alt={stream.title}
          />
          <Badge isPaid={stream.isPaid}>
            {stream.isPaid ? "Premium" : "Free"}
          </Badge>
        </ImageContainer>

        <CardContent>
          <TitleContainer>
            <IconWrapper isPaid={stream.isPaid}>
              <GetIcon icon={"note"} />
            </IconWrapper>
            <CardTitle>{stream.title}</CardTitle>
          </TitleContainer>

          <Description>{stream.description}</Description>

          <Footer>
            <PriceContainer>
              <Price isPaid={stream.isPaid}>
                {stream.isPaid
                  ? `$${formatCurrency(totalAmount)}`
                  : "Free Access"}
              </Price>
              {stream.isPaid && stream.taxPercentage && (
                <TaxInfo>Includes {stream.taxPercentage}% tax</TaxInfo>
              )}
            </PriceContainer>

            <Button isPaid={stream.isPaid} onClick={handlePurchaseClick}>
              {stream.isPaid ? "Purchase Now" : "Start Learning"}
            </Button>
          </Footer>
        </CardContent>
      </Card>

      {isModalOpen && (
        <PopupView
          popupData={
            step === "email" ? (
              <FormContainer
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <AutoForm
                  useCaptcha={false}
                  formType={"post"}
                  header={`Purchase ${stream.title}`}
                  description={"Enter your details to login."}
                  formInput={formInput}
                  submitHandler={submitChange}
                  button={"validate"}
                  isOpenHandler={isCreatingHandler}
                  isOpen={true}
                  key={"lgfrm" + index}
                  plainForm={true}
                />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Title line={false} title="Don't have an account?" />
                  <button
                    onClick={() => setStep("password")}
                    style={{
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                      color: "blue",
                      textDecoration: "underline",
                      padding: 0,
                      font: "inherit",
                    }}
                  >
                    Register
                  </button>
                </div>
              </FormContainer>
            ) : (
              <FormContainer
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <AutoForm
                  useCaptcha={false}
                  formType={"post"}
                  formMode={"double"}
                  header={`Register To Create Account`}
                  description={"Enter your details to register."}
                  formInput={RegisterInput}
                  submitHandler={submitRegisterChange}
                  button={"Register"}
                  isOpenHandler={isCreatingHandler}
                  isOpen={true}
                  key={"regfrm" + index}
                  plainForm={true}
                />
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Title line={false} title="Already have an account?" />
                  <button
                    onClick={() => setStep("email")}
                    style={{
                      cursor: "pointer",
                      background: "none",
                      border: "none",
                      color: "blue",
                      textDecoration: "underline",
                      padding: 0,
                      font: "inherit",
                    }}
                  >
                    Login
                  </button>
                </div>
              </FormContainer>
            )
          }
          themeColors={themeColors}
          closeModal={closeModal}
          itemTitle={{ name: "title", type: "text", collection: "" }}
          openData={{
            data: {
              _id: "print_preparation",
              title:
                step === "email"
                  ? `Purchase ${stream.title}`
                  : "Create Your Account",
            },
          }}
          customClass={"full-page"}
        />
      )}
    </>
  );
};

// Helper functions
const calculateTotalAmount = (amount, taxPercentage) => {
  if (!amount || !taxPercentage) return amount;
  const taxAmount = (amount * taxPercentage) / 100;
  return amount + taxAmount;
};

const formatCurrency = (amount) => {
  if (!amount) return "0.00";
  return amount.toFixed(2);
};

// Main component
const PublicStream = (props) => {
  const [stream, setStream] = useState([]);

  useEffect(() => {
    document.title = `Public Stream - MCQ`;
  }, []);

  useEffect(() => {
    getData({}, "stream").then((response) => {
      setStream(response.data.response);
    });
  }, []);

  return (
    <Container>
      <Content>
        <HeaderContainer>
          <Heading>Available Streams</Heading>
          <Subtitle>Discover our collection of Streams</Subtitle>
        </HeaderContainer>

        <Grid>
          {stream.map((stream, index) => (
            <StreamCard
              key={index}
              stream={stream}
              setMessage={props.setMessage}
              setLoaderBox={props.setLoaderBox}
            />
          ))}
        </Grid>
      </Content>
    </Container>
  );
};

export default withLayout(PublicStream);
